// import bootstrap stuff
@import "./bootstrap/bootstrap-reboot";
@import "./bootstrap/bootstrap-grid";
@import "./bootstrap/bootstrap-utilities";

// vars
$bodyFont: "Montserrat", sans-serif;
$titleFont: "Montserrat", sans-serif;

$smallMax: 767px;
$mediumMax: 991px;
$largeMax: 1199px;
$xlMax: 1399px;

$blue: #2A63B2;
$black: #000000;

body {
  color: $black;
  font-family: $bodyFont;
  font-weight: 400;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 80px 0;
  background-image: url('../images/bg-tile.png');
  background-size: 536px auto;
  background-repeat: repeat;
  background-color: white;
}

.logo-container {
  text-align: center;
}

img.logo {
  margin: 0 auto 57px;
  width: 378px;
  height: auto;
  max-width: 100%;

  @media all and (max-width: $smallMax) {
    max-width: 90%;
  }
}

h2 {
  font-family: $titleFont;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -1.25px;
  font-size: 1.875rem;
  line-height: calc(37/30);
  color: $blue;
  margin-bottom: 27px;
  text-align: center;
}

h3 {
  color: $blue;
  font-size: 1.625rem;
  letter-spacing: -1.08px;
  line-height: calc(32/26);
  margin: 20px 0 0;
  text-align: center;
}

p {
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: -0.75px;
  line-height: calc(22/18);
  text-align: center;

  @media all and (min-width: $largeMax + 1) {
    padding-left: 20px;
    padding-right: 20px;
  }
}